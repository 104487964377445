import { useCallback } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { Draggable } from '@hello-pangea/dnd';

import { ID, IsLoading } from '../../../../../../../types';
import { UpdateTodoItemsFormData } from '../../UpdateTodoItemsForm.types';
import { TodoItemFields } from '../../../../../todoItemsTypes';

import { LexicalEditorField } from '../../../../../../../helpers/FormFields/LexicalEditorField';
import { CheckBoxField } from '../../../../../../../helpers/FormFields/CheckBoxField';
import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../helpers/Translate';

import { IconsEnum } from '../../../../../../../assets/icons/types';

interface UpdateTodoItemsFormItemFieldsProps {
  todoItemFieldId: ID;
  index: number;
  isLoading?: IsLoading;
  control: Control<UpdateTodoItemsFormData>;
  onRemove: (index: number) => void;
  toggleWithoutCheckbox: (index: number) => void;
  error?: string;
}

function UpdateTodoItemsFormItemFields({
  todoItemFieldId,
  index,
  isLoading,
  control,
  onRemove,
  toggleWithoutCheckbox,
  error
}: UpdateTodoItemsFormItemFieldsProps) {
  const withoutCheckbox = useWatch({
    control,
    name: `todoItems.${index}.withoutCheckbox`
  });

  const handleRemove = useCallback<() => void>(() => {
    onRemove(index);
  }, [index, onRemove]);

  const changeWithoutCheckbox = useCallback<() => void>(() => {
    toggleWithoutCheckbox(index);
  }, [index, toggleWithoutCheckbox]);

  return (
    <Draggable draggableId={`${index}-${todoItemFieldId}`} index={index}>
      {(provided) => (
        <div
          className="focus-within:bg-gray-100 dark:focus-within:bg-gray-800 flex gap-1 items-start px-2 py-1 rounded-md"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="flex items-center py-0.5">
            <div className="cursor-move" {...provided.dragHandleProps}>
              <Icon
                icon={IconsEnum.MENU_ALT_SOLID}
                className="h-5 w-5 text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100"
              />
            </div>
          </div>

          {!withoutCheckbox && (
            <div className="flex items-center py-0.5">
              <Controller
                control={control}
                name={`todoItems.${index}.${TodoItemFields.DONE}`}
                render={({ field: { onChange, value, name } }) => (
                  <CheckBoxField
                    id={name}
                    name={name}
                    onChange={onChange}
                    checked={value}
                    disabled={isLoading}
                  />
                )}
              />
            </div>
          )}

          <div className="flex-col flex-1">
            <LexicalEditorField
              inputClassName="markdown min-h-[24px] bg-transparent border-0 focus:ring-0 leading-6 m-0 p-0 pl-2 resize-none rounded w-full outline-none"
              wrapperClassName={
                error
                  ? 'flex flex-1 rounded border border-red-500'
                  : 'flex flex-1'
              }
              disabled={isLoading}
              control={control}
              name={`todoItems.${index}.${TodoItemFields.NAME}`}
            />
            {error && (
              <div className="text-red-500 text-xs">
                <Translate id={error} />
              </div>
            )}
          </div>

          <PureIconButtonHelper
            icon={IconsEnum.TRASH_OUTLINE}
            className="py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0"
            onClick={handleRemove}
            iconClassName="w-5 h-5"
          />

          <PureIconButtonHelper
            icon={withoutCheckbox ? IconsEnum.CHAT : IconsEnum.CHAT_OUTLINE}
            className="py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0"
            onClick={changeWithoutCheckbox}
            iconClassName="w-5 h-5"
          />
        </div>
      )}
    </Draggable>
  );
}

export default UpdateTodoItemsFormItemFields;
