import { gql } from 'graphql-request';

import {
  UserUUID,
  UserFullName,
  UserID,
  UserCreatedAt,
  UserImageFile,
  UserCurrentTeamNanoID
} from '../usersTypes';

export interface FetchSelectFieldUsersQueryResponse {
  id: UserID;
  uuid: UserUUID;
  fullName: UserFullName;
  createdAt: UserCreatedAt;
  image: {
    file: UserImageFile;
  };
  currentTeam: {
    nanoId: UserCurrentTeamNanoID;
  };
}

export const FETCH_SELECT_FIELD_USERS_QUERY = gql`
  query SelectedFieldUsers(
    $filters: UsersFilters
    $sort: [UsersSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    users(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        fullName
        createdAt
        image {
          file
        }
        currentTeam {
          nanoId
        }
      }
      paginationInfo {
        nextPage
      }
    }
  }
`;
