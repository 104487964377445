import React from 'react';
import size from 'lodash/size';

import { ModalSelectProductsFieldControlRequiredProps } from './ModalSelectProductsFieldControl.types';
import { FetchProductsSetByUserIdScopeType } from '../../../../../main/productsSets/productsSetsTypes';

import { useTranslate } from '../../../../../common/hooks/useTranslate';

import { SelectProductsModalButton } from '../../../../library/SelectProductsModalButton';

interface ModalSelectProductsFieldControlProps {
  value: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
  productsSetScope?: FetchProductsSetByUserIdScopeType;
}

function ModalSelectProductsFieldControl({
  value,
  disabled,
  onChange,
  className,
  i18nTitle,
  icon,
  iconClassName,
  i18nText = '',
  modalIcon,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  withoutInitialFocus,
  productsSetScope
}: ModalSelectProductsFieldControlProps &
  ModalSelectProductsFieldControlRequiredProps) {
  const { t } = useTranslate();

  const text = t(i18nText);

  const buttonText = size(value) > 0 ? `${text} (${size(value)})` : text;

  return (
    <SelectProductsModalButton
      value={value}
      disabled={disabled}
      className={className}
      i18nTitle={i18nTitle}
      icon={icon}
      iconClassName={iconClassName}
      modalIcon={modalIcon}
      i18nSubmitText={i18nSubmitText}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      onChange={onChange}
      buttonText={buttonText}
      withoutInitialFocus={withoutInitialFocus}
      productsSetScope={productsSetScope}
    />
  );
}

export default ModalSelectProductsFieldControl;
